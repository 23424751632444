
import { defineComponent } from 'vue'

import VideoBox from '@/components/video-box'

export default defineComponent({
  props: {
    mainBottomItemConfig: {
      type: Object,
      required: true
    }
  },
  components: {
    VideoBox
  },
  setup() {
    return {}
  }
})
