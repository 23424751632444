
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    mainTopItemConfig: {
      type: Object,
      required: true
    }
  },
  setup() {
    const isES = ref(false)
    isES.value = window.location.host === 'es.iioe.org' ? true : false

    return {
      // itemList
      isES
    }
  }
})
