export const mainTopItemConfig = {
  title: 'mainTopTitle',
  itemList: [
    {
      image: require('@/assets/img/main/home/1.png'),
      desc: 'mainTopDesc1'
    },
    {
      image: require('@/assets/img/main/home/2.png'),
      desc: 'mainTopDesc2'
    },
    {
      image: require('@/assets/img/main/home/3.png'),
      desc: 'mainTopDesc3'
    },
    {
      image: require('@/assets/img/main/home/4.png'),
      desc: 'mainTopDesc4'
    }
  ],
  bannerList: [
    {
      image: require('@/assets/img/main/home/carousel_1.png'),
      title: 'bannerTitle1',
      label: 'bannerLabel1',
      desc: 'bannerTopDesc1',
      btnTitle: 'bannerBtn1',
      to: '/course'
    },
    {
      image: require('@/assets/img/main/home/carousel_2.png'),
      title: 'bannerTitle2',
      label: 'bannerLabel2',
      desc: 'bannerTopDesc2',
      btnTitle: 'bannerBtn2',
      to: '/training'
    }
  ]
}

export const mainBottomItemConfig = {
  itemList: [
    {
      title: 'help',
      icon: require('@/assets/img/main/home/5a.png'),
      icon_select: require('@/assets/img/main/home/5.png'),
      desc: 'mainBottomDesc1'
    },
    {
      title: 'construct',
      icon: require('@/assets/img/main/home/6a.png'),
      icon_select: require('@/assets/img/main/home/6.png'),
      desc: 'mainBottomDesc2'
    },
    {
      title: 'provide',
      icon: require('@/assets/img/main/home/7a.png'),
      icon_select: require('@/assets/img/main/home/7.png'),
      desc: 'mainBottomDesc3'
    },
    {
      title: 'build',
      icon: require('@/assets/img/main/home/8a.png'),
      icon_select: require('@/assets/img/main/home/8.png'),
      desc: 'mainBottomDesc4'
    },
    {
      title: 'utilize',
      icon: require('@/assets/img/main/home/9a.png'),
      icon_select: require('@/assets/img/main/home/9.png'),
      desc: 'mainBottomDesc5'
    },
    {
      title: 'upgrade',
      icon: require('@/assets/img/main/home/10a.png'),
      icon_select: require('@/assets/img/main/home/10.png'),
      desc: 'mainBottomDesc6'
    }
  ]
}
