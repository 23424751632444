
import { defineComponent } from 'vue'

import HomeTop from './cpns/home-top.vue'
import HomeMainTop from './cpns/home-main-top.vue'
import HomeMainBottom from './cpns/home-main-bottom.vue'

import { mainTopItemConfig, mainBottomItemConfig } from './config/home.config'

export default defineComponent({
  components: {
    HomeTop,
    HomeMainTop,
    HomeMainBottom
  },
  setup() {
    return {
      mainTopItemConfig,
      mainBottomItemConfig
    }
  }
})
