import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_top = _resolveComponent("home-top")!
  const _component_home_main_top = _resolveComponent("home-main-top")!
  const _component_home_main_bottom = _resolveComponent("home-main-bottom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_home_top),
    _createVNode(_component_home_main_top, { mainTopItemConfig: _ctx.mainTopItemConfig }, null, 8, ["mainTopItemConfig"]),
    _createVNode(_component_home_main_bottom, { mainBottomItemConfig: _ctx.mainBottomItemConfig }, null, 8, ["mainBottomItemConfig"])
  ]))
}