import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4cfb2621")
const _hoisted_1 = { class: "main-top" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "isES"
}
const _hoisted_6 = { class: "banner-section" }
const _hoisted_7 = { class: "banner-container" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "banner-right" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "desc" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_top_carousel = _resolveComponent("top-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$filters.hh_t(_ctx.mainTopItemConfig.title)), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainTopItemConfig.itemList, (item) => {
            return (_openBlock(), _createElementBlock("li", { key: item }, [
              _createElementVNode("img", {
                src: item.image
              }, null, 8, _hoisted_4),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$filters.hh_t(item.desc)), 1),
                (_ctx.isES && item.desc === 'mainTopDesc3')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "in situ"))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_top_carousel, {
        arrow: "always",
        height: _ctx.$isMobile ? '900px' : '460px'
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainTopItemConfig.bannerList, (item) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: item }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: item.image,
                    loading: "lazy",
                    class: "banner-image"
                  }, null, 8, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$filters.hh_t(item.title)), 1),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$filters.hh_t(item.label)), 1),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$filters.hh_t(item.desc)), 1),
                    _createVNode(_component_router_link, {
                      to: item.to,
                      custom: ""
                    }, {
                      default: _withCtx(({ navigate }) => [
                        _createVNode(_component_el_button, {
                          class: "primary-btn",
                          onClick: navigate,
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$filters.hh_t(item.btnTitle)), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["height"])
    ])
  ]))
}