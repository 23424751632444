
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const items = [
      require('@/assets/img/main/banner_01.png'),
      require('@/assets/img/main/banner_02.png')
    ]
    return {
      items
    }
  }
})
